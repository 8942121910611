import { defineComponent } from 'vue';
import ModalBox from '@/components/ModalBox';
import ProductForm from '@/components/Products/ProductForm';
import useProducts from '@/compositions/products';
import { useToast } from 'vue-toastification';
import { cloneDeep } from 'lodash';
export default defineComponent({
    name: 'ProductsOverviewTable',
    components: {
        ModalBox,
        ProductForm
    },
    setup() {
        return { ...useProducts(), toast: useToast() };
    },
    data: () => ({
        showModal: null,
        editProduct: null
    }),
    methods: {
        async deleteProduct(id) {
            try {
                await this.$store.direct.dispatch.products.delete(id);
                this.toast.success('Product deleted');
            }
            catch (error) {
                this.toast.error('Could not delete product', error);
            }
        },
        async updateProduct() {
            try {
                if (this.editProduct === null) {
                    console.error('No product found');
                    return;
                }
                if (this.showModal === null) {
                    console.error('You are currently not editing');
                    return;
                }
                await this.$store.direct.dispatch.products.update({
                    id: this.showModal,
                    update: this.editProduct
                });
                this.toast.success('Product aangepast');
            }
            catch (error) {
                this.toast.error('Could not adjust product', error);
                console.log(error);
            }
            this.showModal = null;
        },
        openModal(productId) {
            const prod = this.products[productId];
            if (prod === undefined) {
                console.error('Cannot find product');
                return;
            }
            this.editProduct = cloneDeep(prod);
            this.showModal = productId;
        }
    }
});
