import { defineComponent } from 'vue';
export default defineComponent({
    name: 'ProductForm',
    props: {
        product: {
            type: Object,
            required: true
        }
    }
});
