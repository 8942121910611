import { defineComponent } from 'vue';
import ProductsOverviewTable from '@/components/Products/ProductsOverviewTable';
import ProductForm from '@/components/Products/ProductForm';
import ModalBox from '@/components/ModalBox';
import { Product } from '@/types/products';
import { useToast } from 'vue-toastification';
export default defineComponent({
    name: 'ProductsOverview',
    components: {
        ProductsOverviewTable,
        ProductForm,
        ModalBox
    },
    setup() {
        return { toast: useToast() };
    },
    data: () => ({
        modalVisible: false,
        product: null
    }),
    computed: {
        currentLocationId() {
            return this.$store.direct.state.locations.currentLocationId;
        }
    },
    methods: {
        showModal() {
            this.product = new Product('', 0, this.currentLocationId);
            this.modalVisible = true;
        },
        async addProduct() {
            if (this.product === null)
                return;
            try {
                await this.$store.direct.dispatch.products.add(this.product);
                this.toast.success('Product successvol toegevoegd');
            }
            catch (error) {
                this.toast.error(`Could not add product ${error}`);
            }
        }
    }
});
